import { APIRequest } from '../../helpers/index.helper';
import { logger } from '../../utils';
import { MANUAL_BOOKING_ENDPOINT } from '../../apiEndpoints/ManualBooking/index.endpoint';

export const getServiceTypeListsService = async ({ queryParams }) => {
  try {
    const payload = {
      ...MANUAL_BOOKING_ENDPOINT.getServiceTypeLists,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    logger(err);
  }
};

export const getTimeSlotListsService = async (queryParams) => {
  try {
    const payload = {
      ...MANUAL_BOOKING_ENDPOINT.getSlot,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const postCreateManualBookingService = async (bodyData) => {
  try {
    const payload = {
      ...MANUAL_BOOKING_ENDPOINT.postCreateManualBooking,
      bodyData,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getServiceProvidersListService = async (queryParams) => {
  try {
    const payload = {
      ...MANUAL_BOOKING_ENDPOINT.getServiceProvidersList,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getAddressListByMobileNumberService = async (mobileNumber) => {
  try {
    const payload = {
      ...MANUAL_BOOKING_ENDPOINT.getAddressListByMobileNumber(mobileNumber),
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const getProviderAvailabilityListService = async (queryParams) => {
  try {
    const payload = {
      ...MANUAL_BOOKING_ENDPOINT.getProviderAvailabilityList,
      queryParams,
    };
    const res = await APIRequest(payload);
    return res;
  } catch (err) {
    console.log(err);
    logger(err);
  }
};

export const providerAvailabilityOptions = () => {
  const style = {
    width: '20px',
    height: '20px',
    marginRight: '5px',
    borderRadius: '50%',
  };

  return [
    {
      name: 'All',
      value: 'all',
    },
    {
      name: 'Available',
      value: 'online',
      icon: <p style={{ ...style, backgroundColor: '#78ac2c' }}></p>,
    },
    {
      name: 'Enroute to Job',
      value: 'begin',
      icon: <p style={{ ...style, backgroundColor: '#be0411' }}></p>,
    },
    {
      name: 'Reached Job',
      value: 'arrived',
      icon: <p style={{ ...style, backgroundColor: '#4d93c7' }}></p>,
    },
    {
      name: 'Journey Started',
      value: 'start',
      icon: <p style={{ ...style, backgroundColor: '#ffd50b' }}></p>,
    },
    {
      name: 'Offline',
      value: 'offline',
      icon: <p style={{ ...style, backgroundColor: '#6e6e6e' }}></p>,
    },
  ];
};

export const zoomLevel = [
  {
    name: '5 Miles Radius',
    value: '30',
  },
  {
    name: '10 Miles Radius',
    value: '20',
  },
  {
    name: '20 Miles Radius',
    value: '10',
  },
  {
    name: '30 Miles Radius',
    value: '5',
  },
];

export const MODAL_TYPE = {
  serviceTypeInfo: {
    name: 'serviceTypeInfo',
    heading: 'text.manualBooking.serviceTypeInfo',
    para: 'text.manualBooking.serviceTypeDetails',
  },
  membershipPlanInfo: {
    name: 'membershipPlanInfo',
    heading: 'Membership Plan Info',
    // para: 'text.manualBooking.serviceTypeDetails',
  },
  bookingDateInfo: {
    name: 'bookingDateInfo',
    heading: 'text.manualBooking.bookingDateInfo',
    para: 'text.manualBooking.bookingDateDetails',
  },
  specialInstInfo: {
    name: 'specialInstInfo',
    heading: 'text.manualBooking.specialInstructionInfo',
    para: 'text.manualBooking.specialInstructionDetails',
  },
  jobTypeInfo: {
    name: 'jobTypeInfo',
    heading: 'text.manualBooking.jobInfo',
    para: 'text.manualBooking.jobDetials',
  },
  howItsWorks: {
    name: 'howItsWorks',
    heading: 'text.manualBooking.howItWorks',
    para: 'text.manualBooking.howWorkHead',
  },
};

export const jobTypeOptions = (isWillCallList) => {
  return [
    {
      name: 'Select Service Provider',
      value: '0',
    },
    {
      name:
        isWillCallList === 1
          ? 'Send to All Will Call List Providers'
          : 'Send to All Available Providers (NAC)',
      value: isWillCallList === 1 ? 'isWillCall' : 'isNAC',
    },
  ];
};
